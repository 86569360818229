import React from 'react';
import MenuUI from '../menu/Menu';

function Layout()
{
    return (
      <div>
        <MenuUI/>
      </div>
    );

};

export default Layout;